
.footer {
  position: relative;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-color: #101732;
  color: #fefefe; 
  padding: 1% 20px;
  box-shadow: 0px -1.5px 1.5px #000000;
}

.footer_listitems {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
li {
  list-style-type: none; 
}