
header {
  padding-top: 150px;
}

section {
  padding: 150px 20px 150px 20px;
  background-color: #FEFEFE;
  background-image: url('../subtle_grunge.png');
}

