.Memory {
    /* width: 25%; */
    margin: 5px auto;
    /* background-color: green; */
    opacity: 0.8;
    border-radius: 13px;
    padding: 1em;
    display: flex;
    justify-content: center;
    border: 1px gray solid;
}

@media only screen and (min-width: 350px) {
    .Memory {
        width: 75%;
        min-width: 350px
    }
}

@media only screen and (min-width: 550px) {
    .Memory {
        width: 65%;
    }
}

@media only screen and (min-width: 800px) {
    .Memory {
        width: 55%;
    }
}