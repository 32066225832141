
* {
  box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
  color: #444;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./subtle_grunge.png');
}

a {
  text-decoration: none;
}