.LandingPage__title {
  font-family: 'Baloo', cursive;
}

.landingpage-header {
  margin: 150px auto;
}

.bgimg-1, .bgimg-2, .bgimg-3, .bgimg-4 {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimg-1 {
  background-image: url('https://images.unsplash.com/photo-1503919545889-aef636e10ad4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80');
  min-height: 800px;
}

.bgimg-2 {
  background-image: url('https://images.unsplash.com/photo-1505377059067-e285a7bac49b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1955&q=80');
  min-height: 100%;
  opacity: 0.95;
}

.bgimg-3 {
  background-image: url('https://images.unsplash.com/photo-1489710437720-ebb67ec84dd2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80');
  min-height: 100%;
}

.bgimg-4 {
  background-image: url('https://images.unsplash.com/photo-1440288736878-766bd5839edb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1953&q=80');
  min-height: 100%;
}

.section-2 {
  color: #c50824;
}

.article_wrapper {
    width: 50%;
    margin: 0 auto;
    background-color: #FBFCFC;
    opacity: 0.8;
    border-radius: 13px;
    padding: 0.5em;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  display: inline-block;
}


.caption span.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
  text-align: center;
  border-radius: 3px;
}

.chevron {
  position: relative;
  left: 0;
  top: 50%;
  width: 100%;
  height: 4em;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0.75;
}

.chevron:hover {
  color: #c50824;
  opacity: 1;
}

.grow:hover
{
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.Button {
  margin: 30px;
  font-size: 14px;
  height: 35px;
  width: 150px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
  color: white;
 }

@media only screen and (min-width: 550px) {
  .Button {
    margin: 30px;
    font-size: 18px;
    height: 70px;
    width: 250px;
    border-radius: 10px;
    border: none;
    box-shadow: 1px 1px 0px 2px rgba (0,0,0,0.3);
    color: white;
   }
}
